body {
    font-family: 'OpenSans',sans-serif !important;
}

.color-blue {
    color: #17AAF0 !important;
}

.bg-blue {
    background: #17AAF0 !important;
}

.main-menu {
    height: 100vh !important;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    margin-top:25px;
}

.main-menu > ul a svg {
    width: 100%;
}
.main-menu > ul a{
    display: flex;
    flex-direction:column;
    padding: 25px;
}

.download-container {
    cursor: pointer;    
    position: absolute;
    z-index: 99999;
    top:-75px;
    left:0;
}

.download-container > svg{
    color: #ffff;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-50{
    margin-top: 50px !important;
}

.p-30{
    padding: 30px !important;
}

/*.top-end{
    display: flex;
    flex-direction: column;
}*/

.form-group {
    margin-bottom: 1rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.logo{
    max-width: 60px;
    margin-left: 20px;
    border-radius: 5px;
}

.main-menu ul{
    padding-top: 100px;
}

.mobile-menu > .MuiAvatar-root:hover {
    background-image: linear-gradient(to right, #17AAF0, #22c8d3) !important;
    background: #e3f2fd;
    border: none;
}

.card-dashboard{
    border-radius: 10px !important;
}

.mobile-menu > .MuiAvatar-root {
    background-color: #e3f2fd;
    background: #e3f2fd;
    color: #17AAF0;
}

.eolien-back{
    position: absolute;
    height: 100%;
    min-width: 700px;
    top:0;
}

.containerVersionDetails{
    position: relative;
}

.list-filter-container {
    margin-bottom: 15px;
}

.logo-login {
    max-width: 100px;
    border-radius: 5px;
}

.name-login{
    font-weight:normal !important;
    opacity: 0.6;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-login p.Mui-error {
    text-align: center;
}

.form-login > div{
    max-width: 300px;
    text-align: center;
}

.form-login {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background: #ffffff;
    padding: 25px;
    width: 100%
}

#outlined-adornment-email-login, #outlined-adornment-password-login, .submit-login {
    padding: 15px !important;
}

#outlined-adornment-email-login~fieldset, #outlined-adornment-password-login~fieldset, .submit-login {
    border-radius: 50px;
}

.submit-login {
    max-width: 200px;
}

nav > div > div.MuiPaper-root{
    top: 0 !important;
}

nav > div > div.MuiPaper-root .scrollbar-container{
    height: 100vh !important;
}

./*MuiBox-root.css-1fntiux, header {
    border-bottom: 1px solid #80808057;
}*/

.w50{
    width: 50px;
}

.img-login {
    background-image: url("../images/login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.card-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;    
}

.bg-gray {
    background: #A1ABC2 !important;
}

.gray-color {
    color: #A1ABC2 !important;
}

.black-color{
    color: black !important;
}

.nom-project-detail{
    font-size:3em;
    font-weight:bold;
}

.opacity1 {
    opacity: 1 !important;
}

.line-details {
    /*margin-bottom: 25px;*/
    min-width: 280px !important;
}

.line-details > a{
    height: 200px;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.v-align-middle{
    vertical-align: middle;
}

.mr-10{
    margin-right: 10px !important
}

.color-white{
    color: #ffff !important;
}

.ml-10 {
    margin-left: 10px !important
}

div[aria-label="Live Customize"] {
    display: none;
}

.imgDetailsMapa img{
    object-fit: cover;
    width: 100%;
}

.user-date{
    font-size: 14px !important;
}

.form-user-image {
    position: absolute;
    width:100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;
    opacity: 0;
    z-index: 9;
}

.card-body {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x) !important;
}

.card-footer > :not(:first-child) {
    margin-left: 0.25rem;
}

.card-footer > :not(:last-child) {
    margin-right: 0.25rem;
}

.imgDetails > img {
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 1px dashed;
}

.imgDetails > img[src="/static/media/image-non-disponible.0056de2e.jpg"] {
    object-fit: contain !important;
}

.h-450{
    height: 450px !important;
}

.spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: #ffffffd6;
    /*background: #030000e0;*/
    /*background: #020202;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.swal2-container {
    background: #030000e0 !important;
    z-index: 9999 !important;
}

.swal2-shown .spinner-container {
    display: none !important;
}

.spinner-container > .spinner {    
    width: 100px;
    height: 100px;
}

.options-data .btn {
    margin: 5px;
}

.preview {    
    position: relative;    
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;    
}

.close-picture-container {
    position: relative;
    width: 100%;
    height:100%
}

.close-picture-container .close {
    position: absolute;
    /*right: -20px;*/
    top: -10px;
    z-index: 10;
}

.user-log {
    display:flex;
    flex-direction:column;
}

#removeUserImg {
    padding: 0px !important;
}

.preview img {
    width: 100%;
}

#picture__input {
  /*display: none;*/
    position: absolute;
    top: 40%;
    left: 40%;
}

.picture {
    width: 100%;
    /*max-width: 400px;*/
    height: 100%;
    /*max-height: 210px;*/
    border-radius: .375rem;
    aspect-ratio: 16/9;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    border: 2px dashed currentcolor;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    top: 0;
    z-index: 10;
}

.version_app{
    color: #A1ABC2 !important;
    font-weight: bold;
}

.logo-term-legal{
    margin: 50px auto !important;
    max-width: 100px;
    padding: 0;
    border-radius: 5px;
}

.text-left{
    text-align: left !important;
}

.main-menu > ul{
    position: inherit;
}

.lg-backdrop.in,
.lg-outer{
    z-index: 9999 !important;
}

.p-relative{
    position: relative;
}

.picture:hover {
    color: #777;
    background: #ccc;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #eee;
}

.picture:focus {
  color: #777;
  background: #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.picture__img {
  max-width: 100%;
}


.list-img{
    width:50px;
    height:50px;
    border-radius:50%;
    object-fit:cover;
}

main{
    background-color: #ffff !important;
    margin-top:0 !important;
    padding-top:90px !important;
    height: calc(100vh - 5px);
    overflow-y: scroll;
}

nav .MuiPaper-root {
    background: #F1F2F5 !important;
}

.color-red {
    color: #dc3545 !important;
}

.options-data {
    display: flex;
    justify-content: space-around;
}

/*.crud-icon-edit:hover, .crud-icon-detail:hover {
    border-color: #17AAF0 !important;
}

.crud-icon-delete:hover {
    border-color: #dc3545 !important;
}*/


/*.crud-icons {
    background-color: transparent;
    padding: 10px 12px !important;
}*/

.crud-icons {
    padding: 8px 10px;
    border: 1px solid;
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;
}

.crud-icons:hover {
    background: #17AAF0;    
    color: #ffff !important;
}

.crud-icons .icon-tabler {
    max-width: 20px !important;
}

.project-details-delete {
    padding: 15px 12px !important;
    background: #17AAF0;    
}

.project-details-delete .icon-tabler-trash{
    color: #ffff !important;
}

.project-details-delete:hover {
    border: 1px solid;
    border-radius: 50%;    
    border-color: #17AAF0;
    background-color: transparent;
}

.project-details-delete:hover .icon-tabler-trash{
    color: #17AAF0 !important;
}

.main-menu > ul a.Mui-selected,
.main-menu > ul a.Mui-selected .MuiListItemIcon-root,
.main-menu > ul a:hover .MuiListItemIcon-root,
.main-menu > ul a:hover {
    color: #17AAF0 !important;
    background-color: transparent !important;
}

.form-switch > .form-check-input,
.form-check-input:checked {
    background-color: #17AAF0 !important;
    border-color: #17AAF0 !important;
}

input[type="checkbox"].form-check-input.disabled-cell {
    background-color: #d0d5e0 !important;
    border-color: #d0d5e0 !important;
}

h4, .card-title{
    font-weight:bold;
}

.color-black{
    color: black !important;
}

.login-last-div hr {
    width: 50px;
    margin-bottom: 20px;
    border-color: #d0d5e0 !important;
}

.swal2-confirm, .swal2-confirm:focus {
    background: #17AAF0 !important;
    box-shadow: none !important;
}

.table-list tbody tr{
    cursor: pointer;
}

.tbar{
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}

.table-list tbody tr.rowActive:hover td,
.table-list tbody tr.versions:hover td,
.rowActive, .versions
 {
    color: #ffff !important;
    background: #071532 !important;
}

.logout-button:hover, .logout-button:hover svg {
    color: #fff;
    background-color: #17AAF0;
}

.map-container {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.h-570{
    height: 570px !important;
}

.max-h-570{
    max-height: 570px !important;
}

.title-card-dashboard svg{
    margin-right: 5px;
}

.title-card-dashboard {
    font-weight: bold;
    padding: 5px !important;
    color: #ffff !important;
    font-weight: 500 !important;
    border-radius: 10px 10px 0 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.card-body > blockquote > strong {
    font-weight: normal !important;
    color: black;
}

.css-11bu8qf-MuiPaper-root-MuiCard-root{
    border: none;
}

main {
    /*padding-top: 0 !important;
    padding-bottom: 0 !important;*/
    position: relative;
    padding-left: 0 !important;
}

/*.custom-icon{*/
    /*width: 50px!important;*/
    /*margin-left: 10px;*/
/*}*/

.custom-icon {
    background-image: url('../images/excel.png');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: cover;
    margin-left: 10px;
}

.m-auto {
    margin: auto;
}

.max-w-300{
    max-width: 300px;
}
.max-w-350{
    max-width: 350px;
}

/*.rowActive .crud-icon-detail {*/
    /*opacity: 0;*/
/*}*/

.rowActive .crud-icons {
    color: #ffff !important;
}

.user-picture{
    border-radius: 50%;
}

.headerProjectDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 9999 !important;
}

.aling-item-center {
    align-items: center !important;
}

.version-lock > .icon-tabler-lock {
    position: absolute;
    top: 15px;
    background: #ffff;
    border-radius: 50%;
    color: #444;
    padding: 3px;
}

.img-version {
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
    height: 100%;
}

.img-version:hover{
    cursor: pointer;
}

.list-filters{
    border-radius: 50px !important;
}

select[name="DivisionId"] > option {
    color: #ffff !important;
    background: #071532 !important;
}

.dropdown-basic, .dropdown-basic:hover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px !important;
    background-color: transparent !important;
    color: #444 !important;
    border-color: #44444447 !important;
}

.dropdown-toggle.show::after {
    border-top: 0 !important;
    border-bottom: .3em solid !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-20{
    padding-left: 20px !important;
}

.dropdown-basic:focus-visible {
    box-shadow: none !important;
}
.dropdown-basic.show{
    border-radius: 20px 20px 0 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.dropdown-menu.show {
    border-radius: 0 0 20px 20px !important;
    background: #071532 !important;
    top: -5px !important;
}

.dropdown-item, .dropdown-item {
    color: #fff !important;
    background-color: transparent !important;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #17AAF0 !important;    
}


.all-list-filter-container {
    margin-bottom: 20px;
}

.container-map.list-project{
    margin-top:70px;
}

.table-container {
    position: relative;
    padding: 10px;
}

.downloadIcon{
    width: 40px;
    height: 40px;
    background: #17AAF0;
    /*background: #131313;*/
    margin-left: 30px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    cursor: pointer;
}

.header-vues{
    justify-content: space-between;
    align-items: center;
}

/*.select {
   position: relative;
   display: flex;
   width: 20em;
   height: 3em;
   line-height: 3;
   overflow: hidden;
}

.select::after {
   content: '\25BC';
   position: absolute;
   top: 0;
   right: 0;
   padding: 0 0.85em;
   cursor:pointer;
   pointer-events:none;
   transition:.25s all ease;
}*/
/*.select select,
.select option {
    border: none;
    outline: none;
    font-size: 18px;
    padding: 5px 55px 5px 5px;    
    color: white;
    -webkit-appearance: none;*/ /* for Safari */
/*margin: 0;
    border-radius: 0;
}*/
.select option {
    background-color: #071532;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
}

.version-title{
    font-size: 35px;
    padding-bottom: 15px;
}

.mb-5px{
    margin-bottom: 5px !important;
}

.mb-30{
    margin-bottom: 30px !important;
}

.mb-40{
    margin-bottom: 40px !important;
}

.underline{
    text-decoration: underline;
}

.container-img-versions{
    /*margin: 25px;*/
    min-width: 220px !important;
}

.card-img-versions{
    width: 200px !important;
    height: 150px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    position: relative;
}

.container-img-versions .icon-tabler-eye {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background: #17AAF0;
    border-color: #17AAF0;
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: #ffff
}

/*.container-img-versions:hover {
    background: #17AAF0;
    border-radius: 15px;
}*/
/*.container-img-versions:hover .img-version {
    opacity: 0;
}

.container-img-versions:hover .icon-tabler-eye {
    background: #ffff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
}*/
.container-img-versions {
    position: relative;
    margin-bottom: 25px;
}

.logo-container-inside {
    /*position: absolute;
    top: 20px;*/
    display: flex;
    justify-content: center;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: radial-gradient(farthest-side,currentColor 90%,#fff);
    background-position: center center;
    background-repeat: no-repeat;
}

.white-space-pre{
    white-space: pre !important;
}

.dot-details{
    width: 25px;
    height: 25px;
}

.details-container .icon-tabler-trash{
    max-width: 30px !important;
}

.bt-0{
    border-top: 0 !important;
}

.justify-content-center {
    justify-content: center !important;
}

.btn-primary {
    background: #17AAF0 !important;
    border-color: #17AAF0 !important;
}

.custom-button svg {
    width: 20px;
}

.btn-icon, .btn-icon-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-icon svg{
    margin-right: 5px;
}

.table-list .cut-text{
    /*white-space: pre !important;*/
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.myTooltipContainer{
    position: relative;
    top: 0px;
    left: 0px;
    cursor: pointer;
    border-radius: 6px;
}
.myTooltipContainerVisor{
    position: relative;
    top: 0px;
    left: 0px;
    cursor: pointer;
    border-radius: 6px;
}
.myTooltip{
    position: absolute;
    bottom: 150%;
    /*background: #17AAF0;*/
    background: #ffff;
    /*color: #ffff;*/
    color: black;
    padding: 10px;
    text-align: center;
    line-height: 2;
    border-radius: 6px;
    transition: 0.5s;
    opacity: 0;
    display: none;
    z-index:1;
    box-shadow: 0px 9px 18px 3px rgba(0,0,0,0.33);
}

#tr-0 .myTooltip,
#tr-1 .myTooltip{
    top: 150%;
    bottom: auto;
}

.myTooltipVisor{
    position: absolute;
    top: 130%;
    right: 0;
    /*background: #17AAF0;*/
    background: #ffff;
    /*color: #ffff;*/
    color: black;
    padding: 10px;
    text-align: center;
    line-height: 2;
    border-radius: 6px;
    transition: 0.5s;
    opacity: 0;
    display: none;
    z-index: 99999 !important;
    box-shadow: 0px 9px 18px 3px rgba(0,0,0,0.33);
}
.myTooltipVisor::after{
    content: "";
    position: absolute;
    top: -5px;
    right: 25px;
    transform: translateX(-50%) rotate(45deg);
    transition: 0.5s;
    opacity: 0;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    /*border-color:transparent #17AAF0 #17AAF0 transparent;*/
    border-color:#ffff transparent transparent #ffff;
    box-shadow: 0px 9px 18px 3px rgba(0,0,0,0.33);
    z-index: 99999 !important;
}
.myTooltip::after{
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    transition: 0.5s;
    opacity: 0;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    /*border-color:transparent #17AAF0 #17AAF0 transparent;*/
    border-color:transparent #ffff #ffff transparent;
    /*box-shadow: 0px 9px 18px 3px rgba(0,0,0,0.33);*/
}

#tr-0 .myTooltip:after,
#tr-1 .myTooltip:after{
    bottom: auto;
    top: -5px;
    border-color: #ffff transparent transparent #ffff
}

.myTooltipContainer:hover .myTooltip::after,
.myTooltipContainerVisor:hover .myTooltipVisor::after,
.myTooltipContainer:hover .myTooltip,
.myTooltipContainerVisor:hover .myTooltipVisor
{
    opacity: 1;
    display: inline;
}

.btn-icon-right svg{
    margin-left: 5px;
}

.custom-button-secondary svg{
    width: 20px;
}

.custom-button {
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-image: linear-gradient(to right, #17AAF0, #22c8d3) !important;
    padding: 12px 35px;
    border: none;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);*/
    box-shadow: 0px 9px 18px 3px rgba(0,0,0,0.33);
    font-weight: bold;
}

.custom-button-secondary,
.custom-button-secondary:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 12px 35px;
    /*border: none;*/
    border-radius: 50px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);*/
    /*box-shadow: 1px 17px 53px -3px rgba(0,0,0,0.33);*/
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.10);
    background: transparent !important;
    color: #17AAF0;
    border-color: #17AAF0;
    font-weight: bold;
}

.card-details .card-img-top {
    height:150px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.card-details {
    height: 190px !important;
    width: 260px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    border: none;
    margin-bottom: 25px;
}

.card-details .ellipsis{
    width: 230px !important;
}
/*.title-version{
    text-align: center;
}*/
.version-card{
    text-decoration: none !important;
}
.card-body-version{
    padding: 0 15px !important;
    display: flex;
    align-items: center;
}

.text-right{
    text-align: right !important;
}

.pl-25{
    padding-left: 25px !important;
}

.details-header{
    margin-top: 30px;
}
header{
    background: transparent !important;
}

.back-white{
    background: #ffffff !important;
}

header .MuiButtonBase-root.MuiChip-root{
    border-color: transparent ;
    background-color: #ffffff;
    margin-right: 30px;
}
#settingIcon{
    max-width: 20px;
}
header .MuiButtonBase-root.MuiChip-root:hover #settingIcon{
    content:url("../images/icons/white-settings.png");
}
header .MuiButtonBase-root.MuiChip-root:hover .user-name,
header .MuiButtonBase-root.MuiChip-root:hover .user-rol{
    color: #ffff !important;
}
header .MuiButtonBase-root.MuiChip-root:hover,
.bg-gradient {
    background-image: linear-gradient(to right, #17AAF0, #22c8d3) !important;
    border: none;
}

.icon-menu-dashboard{
    max-width: 20px;
    margin-right: 5px;
}

/***Tree table***/
.arrow {
    cursor: pointer;
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-left: 8px;
    transition: transform .1s ease-in-out;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
/***Tree table***/

.disabled-cell img {
    opacity: 0.5;
}
.disabled-cell .crud-icons {
    color: #d0d5e0 !important;
}
.disabled-cell {
    /*background: #d0d5e0 !important;*/
    color: #d0d5e0 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;    
}

.form-switch, .form-switch > input[type=checkbox] {
    cursor: pointer;
}

.w-20 {
    width: 20%;
}

.w-80{
    width: 80%;
}

/*.datosDetails{*/
    /*padding-top: 10px;*/
    /*width:100%;    */
/*}*/

/*.details-project {*/
    /*margin-top: 100px;*/
    /*height: 100vh;*/
/*}*/

.main-menu > ul a {
    color: #A1ABC2 !important;
    background: transparent !important;
}

.main-menu > ul a[href="/projects/list"] svg {
    background-image: url("../images/icons/projects.png");
    color: #17AAF0;
}
.main-menu > ul a[href="/projects/list"]:hover svg, .main-menu > ul a.Mui-selected[href="/projects/list"] svg {
    background-image: url("../images/icons/projectsSelected.png");
}

.main-menu > ul a[href="/dashboard/default"] svg {
    background-image: url("../images/icons/dashboard.png");
}
.main-menu > ul a[href="/dashboard/default"]:hover svg, .main-menu > ul a.Mui-selected[href="/dashboard/default"] svg {
    background-image: url("../images/icons/dashboardSelected.png");
}

.main-menu > ul a[href="/utilisateurs/list"] svg {
    background-image: url("../images/icons/users.png");
}
.main-menu > ul a[href="/utilisateurs/list"]:hover svg, .main-menu > ul a.Mui-selected[href="/utilisateurs/list"] svg {
    background-image: url("../images/icons/usersSelected.png");
}


.main-menu > ul a[href="/mentions_legales"] svg {
    background-image: url("../images/icons/mentions_legales.png");
}
.main-menu > ul a[href="/mentions_legales"]:hover svg, .main-menu > ul a.Mui-selected[href="/mentions_legales"] svg {
    background-image: url("../images/icons/mentions_legalesSelected.png");
}

.ril__toolbarRightSide{
    display: flex;
    align-items: center;
}

.ril-zoom-in, .ril-zoom-out {
    display: none;
}

.download-button{
    cursor: pointer;
}

.downloadImgIcon{
    background-image: url("../images/icons/downloadImgIcon.png") !important;
}
.downloadVideoIcon{
    background-image: url("../images/icons/downloadVideoIcon.png") !important;
}
.myTooltipContainerVisor:hover .downloadVideoIcon,
.myTooltipContainerVisor:hover .downloadImgIcon{
    opacity: 1;
}
.downloadVideoIcon, .downloadImgIcon{
    background-size: contain !important;
    width: 30px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    opacity: 0.7;
    float: right;
    margin: 10px 12px;
}

.lg-download.lg-icon{
    display: none;
}

.datagridHeader{
    white-space: pre !important;
}

.main-menu > ul a[href="/contacts"]{
    display: none;
}

.main-menu > ul a[href="/contacts"] svg {
    background-image: url("../images/icons/contact.png");
}
.main-menu > ul a[href="/contacts"]:hover svg, .main-menu > ul a.Mui-selected[href="/contacts"] svg {
    background-image: url("../images/icons/contactSelected.png");
}

.main-menu > ul a svg circle {
    display: none;
}

.main-menu > ul a h5,
.main-menu > ul a:hover p
{
    font-weight: bold;
    color: #17AAF0 !important;
}

.main-menu > ul a p{
    font-weight: bold;
}

main{
    overflow-x: hidden !important;
    margin: 0 !important;
}

.w-50{
    width: 50px !important;
}

.gridtable tbody{
    display: block;
    max-height: 570px;
    overflow-y: auto;
}
.gridtable thead, .gridtable tbody tr{
    display: table;
    table-layout: fixed;
    width: 100%;
}

.gridtable thead{
    position: relative;
}
.gridtable thead th:last-child:after{
    content: ' ';
    position: absolute;
    width: 1.3em;
    height: 38px;
    right: -1.3em;
    top: 0;
}

.main-menu > ul a svg {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 30px;
    height: auto;
}

.projectsVersions img.card-img-top, .container-img-versions img {
    /*height: 200px;*/
    object-fit: cover;
    /*object-fit: contain;*/
    /*background: #17AAF0;
    background: #071532;
    background-image: linear-gradient(to bottom, #17AAF0, #06f6e7);*/
    /*background: #82878447;*/
}

.projectsVersions{
    padding-top: 30px;
}

.imgVersion{
    /*top: -92px;*/
    z-index: 9999;
    /*position: relative;*/
}

.error-alert > .swal2-modal.swal2-show{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.imgVersion img {
    height: 100%;
    object-fit:cover;
    top:0;
    bottom:0;
}
.title-card-dashboard {
    position: relative
}

.graphic-bar {
    border: 1px solid silver;
    position: relative
}

.information-icon-bar {
    position: absolute;
    top: 10px;
    right: 30px;
}

.information-icon-card {
    float: right;
}

.f-grow-1{
    flex-grow: 1;
}

.dashboard-tooltip {
    background: #17AAF0;
    color: #fff;
}

@media (min-width: 960px) {
    .download-container {        
        left: -130px;
    }
    .details-container {
        display: flex;
        /*position: absolute;*/
        right: 0;
        bottom: 0;
        left: -20px;
        top: -88px;
    }
    .datosDetails {
        padding-left: 50px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
    }
    .imgVersion {
        height: 100vh;
        /*display: flex;
        align-items: center;
        background: #17AAF0;
        background: #071532;
        background-image: linear-gradient(to bottom, #17AAF0, #06f6e7);*/
        /*background: #82878447;*/
    }

    .imgVersion img{
        position: fixed;
    }

    .details-container {
        /*top: 5px;*/
        top: 0;
        left: 0;
    }
    .mobile-menu {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.min-w-220{
    min-width: 220px !important;
}

.fullwidth-block {
    width: 100vw !important;
    position: relative !important;
    margin-left: calc(-50vw + 50%) !important;
}

@media (max-width: 959px) {
    .img-login {
        display: none;
    }
    .logo-container-inside{
        display: none;
    }
    .imgVersion, .details-project{
        width: 100% !important;
    }
    main{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.Mui-disabled {
    background: #d0d5e0 !important;
    color: #ffff !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
}

@media (max-width: 767px) {
    .footer-details-version{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0 !important;
        padding: 0 !important;
    }

    .eolien-back{
        display: none;
    }
}
@media (min-height: 700px) {
    .main-menu > ul > a[href="/mentions_legales"]{
        position: absolute;
        bottom: 0;
        text-align: center !important;
        padding-left: 0 !important;
    }
}